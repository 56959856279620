import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FileViewer from 'react-file-viewer';
import { DeleteAttachmentModal } from '../content';

export const PreviewAttachmentModal = ({
  deleteAttachment,
  fileType,
  filePath,
  isEdit,
  isLoading,
  previewAttachment,
  previewModalFlag
}) => {
  const [isDeleteAttachmentModalOpen, setIsDeleteAttachmentModalOpen] = useState(false);
  const mountedRef = useRef(null);

  const toggleDeleteAttachmentModal = () => {
    setIsDeleteAttachmentModalOpen(!isDeleteAttachmentModalOpen);
  };

  useEffect(() => {
    mountedRef.current = true;

    if (mountedRef.current && !previewModalFlag && isDeleteAttachmentModalOpen) {
      setIsDeleteAttachmentModalOpen(false);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [isDeleteAttachmentModalOpen, previewModalFlag]);

  return (
    <>
      <Modal isOpen={previewModalFlag} toggle={previewAttachment} className="modal-lg modal-xl">
        <ModalHeader title={''}>Attachment Preview</ModalHeader>
        <ModalBody className="vh-50 vh-md-75">
          <FileViewer fileType={fileType} filePath={filePath} onError={() => {}} />
        </ModalBody>
        <ModalFooter>
          {Boolean(isEdit) && (
            <Button color="danger" onClick={() => toggleDeleteAttachmentModal()}>
              Delete
            </Button>
          )}
          <Button onClick={previewAttachment}>Close</Button>
        </ModalFooter>
      </Modal>
      <DeleteAttachmentModal
        deleteAttachmentConfirm={deleteAttachment}
        deleteAttachmentModal={previewModalFlag && isDeleteAttachmentModalOpen}
        isSpinner={isLoading}
        toggleDeleteAttachmentModal={toggleDeleteAttachmentModal}
      />
    </>
  );
};

PreviewAttachmentModal.propTypes = {
  deleteAttachment: PropTypes.func,
  fileType: PropTypes.string,
  filePath: PropTypes.string,
  isEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  previewAttachment: PropTypes.func,
  previewModalFlag: PropTypes.bool
};

PreviewAttachmentModal.defaultProps = {
  deleteAttachment: () => {},
  filePath: '',
  fileType: '',
  isEdit: false,
  isLoading: false,
  previewAttachment: () => {},
  previewModalFlag: false
};
