import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ContentDetailCardBody, ContentDetailCardFooter, ContentDetailCardHeader } from '../view';
import { testletStatusTypes } from '../../../../helpers/testletTypes';

export const ViewQuestionDetailCard = ({ isCollaboration, questionId, questionData, showError }) => {
  const { content_type, is_flagged, is_published, testlet_type: contentStatus, question } = questionData;
  const { question_stem } = question;
  const { text } = question_stem;
  const questionTitle = text || '';
  const isApproved = testletStatusTypes.APPROVED.includes(contentStatus);

  return (
    <Fragment>
      <ContentDetailCardHeader
        contentId={questionId}
        contentStatus={contentStatus}
        contentType={content_type}
        isApproved={isApproved}
        isCollaboration={isCollaboration}
        isFlagged={is_flagged}
        isPublished={is_published}
        title={questionTitle}
      />
      <ContentDetailCardBody contentData={questionData} showError={showError} />
      <ContentDetailCardFooter />
    </Fragment>
  );
};

ViewQuestionDetailCard.propTypes = {
  isCollaboration: PropTypes.bool,
  questionId: PropTypes.string.isRequired,
  questionData: PropTypes.object.isRequired,
  showError: PropTypes.bool
};

ViewQuestionDetailCard.defaultProps = { isCollaboration: false, showError: false };
