import { v4 as uuidv4 } from 'uuid';
import { contentTypes } from '../../data/content/contentTypes';

export const copiedContentConverter = (currentTimeStamp, newData, user) => {
  const { content_type: contentType } = newData;
  const { displayName, uid } = user;

  delete newData.id;
  delete newData['_highlightResult'];
  newData.content_id = null;
  newData.collaboratorIds = [];
  newData.created_by = uid;
  newData.created_on = currentTimeStamp;
  newData.deleted_on = null;
  newData.is_deleted = false;
  newData.is_display_testlet = true;
  newData.is_flagged = false;
  newData.is_latest_version = true;
  newData.is_public = false;
  newData.is_published = false;
  newData.is_testlet_cloned = true;
  newData.last_submitted_by = null;
  newData.opened_for_collaboration_by = null;
  newData.published_inbdeCourse = [];
  newData.published_versions = [];
  newData.testlet_type = '1A';
  newData.testlet_uid = uuidv4();
  newData.updated_on = currentTimeStamp;
  newData.version = 0;

  if (contentType === contentTypes.question.type) {
    newData.question.collaborators = [];
    newData.question.created_at = currentTimeStamp;
    newData.question.created_by = uid;
    newData.question.creator_type = 'originator';
    newData.question.question_creator = displayName;
    newData.question.uuid = uuidv4();
  } else {
    newData.testlet_information.collaboratos = [];
    newData.testlet_information.testlet_creator = displayName;
    newData.testlet_information.testlet_title = '';
  }

  return newData;
};

export const getInbdeCourseName = contentData => {
  const { content_type, inbde_course, testlet_information } = contentData;
  let inbdeCourse = '';

  if (content_type === contentTypes.question.type) {
    inbdeCourse = inbde_course && inbde_course['label'] ? inbde_course['label'] : '';
  } else if (testlet_information) {
    const { inbdeCourse: testletInbdeCourse } = testlet_information;
    inbdeCourse = testletInbdeCourse && testletInbdeCourse['label'] ? testletInbdeCourse['label'] : '';
  }

  return inbdeCourse;
};
