import React from 'react';
import PropTypes from 'prop-types';
import TypesBadge from '../TypeBadge';
import types from '../../data/testlet/type';
import { contentTypes } from '../../data/content/contentTypes';
import { testletTypes } from '../../helpers/testletTypes';

export const ContentStatusBadge = ({ contentId, contentStatus, contentType }) => {
  let className = '';
  let text = '';
  let tooltip = '';

  const selectedContentType = contentTypes[contentType] || contentTypes['testlet'];
  const contentName = selectedContentType.type;

  types.forEach(type => {
    if (type.title === contentStatus.toString()) {
      className = type.class;
      text = type.text;
      tooltip = type.tooltip.replace(new RegExp('testlet', 'g'), contentName);
      return;
    }
  });
  const details = { className, text, tooltip };
  if (contentType === contentTypes.question.type && contentStatus === testletTypes.OPEN_FOR_COLLABORATION) {
    details['text'] = 'Approved';
  }

  return <TypesBadge contentId={contentId} details={details} />;
};

ContentStatusBadge.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contentType: PropTypes.string
};

ContentStatusBadge.defaultProps = {
  contentType: ''
};
