const featureToggles = {
  isEnabledPatientBoxEditByCollaborators: 'ENABLE_PATIENT_BOX_EDIT_BY_COLLABORATORS',
  isCommentsFeatureEnabled: 'ENABLE_COMMENTS_FEATURE_FOR_USERS',
  isChangesFeatureVisibleToFaculty: 'ENABLE_CHANGES_VISIBLE_TO_FACULTY',
  isShowSearchOptions: 'ENABLE_DISPLAY_SEARCH_OPTIONS',
  isQuestionReorderEnabled: 'ENABLE_QUESTION_REORDER',
  isTestletAutoSaveEnabled: 'ENABLE_AUTO_SAVE_TESTLET',
  isReportsGenerationFeatureEnabled: 'ENABLE_REPORTS_GENERATION',
  isUploadCourseListEnabled: 'ENABLE_UPLOAD_COURSE_LIST',
  isFetchCourseList: 'ENABLE_FETCH_COURSE_LIST',
  isTestletSearchAndFilteringAvailable: 'ENABLE_TESTLET_SEARCH_AND_FILTERING',
  isFetchInbdeAccessMembers: 'ENABLE_FETCH_INBDE_ACCESS_MEMBERS',
  isUpdateWhitelistEnabled: 'ENABLE_UPDATE_USER_WHITELIST',
  isStandAloneQuestionsEnabled: 'ENABLE_STAND_ALONE_QUESTIONS',
  isEmailUsersForCollaboration: 'ENABLE_EMAIL_USERS_FOR_COLLABORATION',
  isSaveLmsExportFileEnabled: 'ENABLE_SAVE_LMS_EXPORT_FILE'
};

export default featureToggles;
