import React from 'react';
import PropTypes from 'prop-types';
import TypesBadge from '../TypeBadge';
import adminTypes from '../../data/testlet/adminTypes';
import { contentTypes } from '../../data/content/contentTypes';

export const AdminStatusBadge = ({ contentId, contentStatus, contentType }) => {
  let className = '';
  let text = '';
  let tooltip = '';

  const selectedContentType = contentTypes[contentType] || contentTypes['testlet'];
  const contentName = selectedContentType.name;

  adminTypes.forEach(type => {
    if (type.title === contentStatus) {
      className = type.class;
      text = 'Type: ' + type.title;
      tooltip = type.tooltip.replace('Testlet', contentName);
      return;
    }
  });
  const details = { className, text, tooltip };

  return <TypesBadge contentId={contentId + '-admin'} details={details} />;
};

AdminStatusBadge.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contentType: PropTypes.string
};

AdminStatusBadge.defaultProps = {
  contentType: 'question'
};
