import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ContentSummaryCardBody, ContentSummaryCardFooter, ContentSummaryCardHeader } from '../view';
import {
  getCollaboratorNameArray,
  getCourseSemesterText,
  getInbdePrepCourseText,
  getMockExamText
} from '../helpers/helpers';

export const ViewQuestionSummaryCard = ({ isCollaboration, isUserAdmin, questionData, questionId }) => {
  const {
    content_type,
    inbde_course,
    is_public,
    is_published,
    published_inbdeCourse,
    question,
    testlet_type,
    version
  } = questionData;
  const { collaborators, mockExam, question_creator, question_stem } = question;
  const { text } = question_stem;

  const courseSemesterText = getCourseSemesterText(question);
  const inbdePrepCourseText = getInbdePrepCourseText(inbde_course, published_inbdeCourse);
  const mockExamText = getMockExamText(mockExam);
  const questionCollaborators = getCollaboratorNameArray(collaborators);
  const questionTitle = text || 'Stand-Alone Question';

  return (
    <Fragment>
      <ContentSummaryCardHeader
        contentId={questionId}
        contentStatusType={testlet_type}
        contentType={content_type}
        isPublic={is_public}
        isPublished={is_published}
        showCollabBadge={isCollaboration}
        title={questionTitle}
      />
      <ContentSummaryCardBody
        collaborators={questionCollaborators}
        contentCreator={question_creator}
        contentType={content_type}
        courseInformation={courseSemesterText}
        inbdePrepCourse={inbdePrepCourseText}
        mockExams={mockExamText}
        version={version}
      />
      <ContentSummaryCardFooter
        contentId={questionId}
        contentStatus={testlet_type}
        contentType={content_type}
        isUserAdmin={isUserAdmin}
        openedDaysAgoText={''}
      />
    </Fragment>
  );
};

ViewQuestionSummaryCard.propTypes = {
  isCollaboration: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  questionData: PropTypes.object.isRequired,
  questionId: PropTypes.string.isRequired
};
