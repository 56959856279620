import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Row } from 'reactstrap';
import GoogleSignIn from './GoogleSignIn';

const SocialAuthButtons = ({ setEmail }) => {
  return (
    <FormGroup className="mb-0">
      <Row noGutters>
        <div className="pr-sm-1 mr-auto ml-auto col-12 col-md-9">
          <GoogleSignIn setEmail={setEmail} />
        </div>
      </Row>
    </FormGroup>
  );
};

SocialAuthButtons.propTypes = {
  setEmail: PropTypes.func.isRequired
};

export default SocialAuthButtons;
