import React from 'react';
import PropTypes from 'prop-types';
import { CardHeader, Row } from 'reactstrap';
import {
  CollaborationBadge,
  ContentStatusBadge,
  PublishedBadge,
  UnpublishedBadge
} from '../../../widgets/content-badges/contentBadges';
import { contentTypes } from '../../../data/content/contentTypes';
import { testletStatusTypes } from '../../../helpers/testletTypes';

export const ContentSummaryCardHeader = ({
  contentId: id,
  contentStatusType,
  contentType,
  isPublic,
  isPublished,
  showCollabBadge,
  title
}) => (
  <CardHeader className="bg-100 pb-2">
    <Row>
      <div className="col-12 col-md-6 pt-1 pb-1">
        <h5 className="text-truncate">{title}</h5>
      </div>
      <div className="col-12 col-md-6 pt-1 pb-1">
        <Row>
          {isPublished ? (
            <h5 className="cursor-pointer mr-2 mb-2 mb-md-0 ml-md-auto ml-2">
              <PublishedBadge contentId={id} />
            </h5>
          ) : (
            isPublic && (
              <h5 className="cursor-pointer mr-2 mb-2 mb-md-0 ml-md-auto ml-2">
                <UnpublishedBadge contentId={id} />
              </h5>
            )
          )}
          {showCollabBadge ? (
            <h5 className={'cursor-pointer mb-0 ml-2 ' + (isPublic ? 'mr-2 ml-md-0' : 'ml-md-auto mr-4')}>
              <CollaborationBadge contentId={id} />
            </h5>
          ) : (
            <h5 className={'cursor-pointer mb-0 ml-2 ' + (isPublic ? 'mr-2 ml-md-0' : 'ml-md-auto mr-4')}>
              {contentType === contentTypes.question.type && testletStatusTypes.APPROVED.includes(contentStatusType) ? (
                ''
              ) : (
                <ContentStatusBadge contentId={id} contentStatus={contentStatusType} contentType={contentType} />
              )}
            </h5>
          )}
        </Row>
      </div>
    </Row>
  </CardHeader>
);

ContentSummaryCardHeader.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.string,
  contentStatusType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isPublic: PropTypes.bool,
  isPublished: PropTypes.bool,
  showCollabBadge: PropTypes.bool,
  title: PropTypes.string
};

ContentSummaryCardHeader.defaultProps = {
  contentType: 'testlet',
  isPublic: false,
  isPublished: false,
  showCollabBadge: false,
  title: ''
};
