import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { contentTypes } from '../../../data/content/content';
import { ViewQuestionSummaryCard } from './stand-alone-question/ViewQuestionSummaryCard';
import { ViewContentDetailCard } from './ViewContentDetailCard';
import { isIterableArray } from '../../../helpers/utils';

export const ViewContentSummaryCard = ({
  ajaxSuccess,
  contentData,
  contentId,
  handleContentAction,
  isCollaboration,
  isReview,
  isUserAdmin,
  pageTitle,
  userId
}) => {
  const [isShowDetailedView, setIsShowDetailedView] = useState(false);
  const { content_type, questions } = contentData;
  const isPartialData = content_type === contentTypes.testlet.type && !isIterableArray(questions);

  const toggleDetailedViewModal = () => {
    setIsShowDetailedView(!isShowDetailedView);
  };

  let contentTypeCard;
  if (content_type === contentTypes.question.type) {
    contentTypeCard = (
      <ViewQuestionSummaryCard
        isCollaboration={isCollaboration}
        isUserAdmin={isUserAdmin}
        questionData={contentData}
        questionId={contentId}
      />
    );
  } else contentTypeCard = <div />;

  return (
    <Fragment>
      <Card className="mb-2 cursor-pointer" onClick={() => toggleDetailedViewModal()}>
        {contentTypeCard}
      </Card>
      <ViewContentDetailCard
        contentData={contentData}
        contentId={contentId}
        handleContentAction={handleContentAction}
        isCollaboration={isCollaboration}
        isOpen={isShowDetailedView}
        isPartialData={isPartialData}
        isReview={isReview}
        isUserAdmin={isUserAdmin}
        toggleView={toggleDetailedViewModal}
        userId={userId}
      />
    </Fragment>
  );
};

ViewContentSummaryCard.propTypes = {
  ajaxSuccess: PropTypes.func,
  contentData: PropTypes.object.isRequired,
  contentId: PropTypes.string.isRequired,
  handleContentAction: PropTypes.func,
  isCollaboration: PropTypes.bool,
  isReview: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  pageTitle: PropTypes.string,
  userId: PropTypes.string
};

ViewContentSummaryCard.defaultProps = {
  ajaxSuccess: () => {},
  handleContentAction: () => {},
  isCollaboration: false,
  isReview: false,
  isUserAdmin: false,
  pageTitle: '',
  userId: ''
};
