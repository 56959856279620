import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';

export const DeleteAttachmentModal = ({
  deleteAttachmentConfirm,
  deleteAttachmentModal,
  isSpinner,
  toggleDeleteAttachmentModal
}) => {
  return (
    <Modal isOpen={deleteAttachmentModal} toggle={toggleDeleteAttachmentModal}>
      <ModalHeader title={''}>Delete Attachment</ModalHeader>
      <ModalBody className="no-scrollbar-container">
        <h6>Are you sure you wish to delete this attachment?</h6>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={deleteAttachmentConfirm} disabled={isSpinner}>
          Delete
          {isSpinner && <Spinner size="sm" color="light" className="ml-2" />}
        </Button>
        <Button onClick={toggleDeleteAttachmentModal} disabled={isSpinner}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

DeleteAttachmentModal.propTypes = {
  deleteAttachmentConfirm: PropTypes.func,
  deleteAttachmentModal: PropTypes.bool,
  isSpinner: PropTypes.bool,
  toggleDeleteAttachmentModal: PropTypes.func
};

DeleteAttachmentModal.defaultProps = {
  deleteAttachmentConfirm: () => {},
  deleteAttachmentModal: false,
  isSpinner: false,
  toggleDeleteAttachmentModal: () => {}
};
