import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import {
  CollaborationBadge,
  ContentStatusBadge,
  ContentTypeBadge,
  PublishedBadge,
  UnpublishedBadge
} from '../../../widgets/content-badges/contentBadges';
import { contentTypes } from '../../../data/content/contentTypes';
import { testletStatusTypes } from '../../../helpers/testletTypes';

export const ContentDetailCardHeader = ({
  contentId,
  contentStatus,
  contentType,
  isApproved,
  isCollaboration,
  isFlagged,
  isPublished,
  title
}) => {
  const isQuestion = contentType === contentTypes.question.type;
  const showCollabBadge = !isApproved && isCollaboration;

  return (
    <Row className="pl-3 pr-2 pt-3 pb-3">
      <div className={'d-flex col-12 ' + (isFlagged ? '' : 'col-lg-6')}>
        <h5 className="cursor-pointer mr-2 mb-2 mb-lg-0 mt-auto">
          <ContentTypeBadge contentId={contentId} contentType={contentType} />
        </h5>
        <h5 className="mb-2 mb-lg-0 mt-auto text-truncate">{title}</h5>
      </div>
      {!isFlagged && (
        <div className="col-12 col-lg-6">
          <Row>
            {isPublished ? (
              <h5 className="cursor-pointer mr-2 mb-2 mb-md-0 ml-lg-auto ml-2">
                <PublishedBadge contentId={contentId} />
              </h5>
            ) : (
              isApproved && (
                <h5 className="cursor-pointer mr-2 mb-2 mb-md-0 ml-lg-auto ml-2">
                  <UnpublishedBadge contentId={contentId} />
                </h5>
              )
            )}
            {showCollabBadge ? (
              <h5 className={'cursor-pointer mb-0 ml-2 mr-4 ' + (isApproved ? 'ml-lg-0' : 'ml-lg-auto')}>
                <CollaborationBadge contentId={contentId} />
              </h5>
            ) : (
              <h5 className={'cursor-pointer mb-0 ml-2 mr-4 ' + (isApproved ? 'ml-lg-0' : 'ml-lg-auto')}>
                {isQuestion && testletStatusTypes.APPROVED.includes(contentStatus) ? (
                  ''
                ) : (
                  <ContentStatusBadge contentId={contentId} contentStatus={contentStatus} contentType={contentType} />
                )}
              </h5>
            )}
          </Row>
        </div>
      )}
    </Row>
  );
};

ContentDetailCardHeader.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentStatus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  contentType: PropTypes.string,
  isApproved: PropTypes.bool,
  isCollaboration: PropTypes.bool,
  isFlagged: PropTypes.bool,
  isPublished: PropTypes.bool,
  title: PropTypes.string.isRequired
};

ContentDetailCardHeader.defaultProps = {
  contentType: 'testlet',
  isApproved: false,
  isCollaboration: false,
  isFlagged: false,
  isPublished: false
};
