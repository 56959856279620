import { isObjectNullOrEmpty, isUserAccessOfTypeAdmin } from '../../../../helpers/inbdeUtils';
import { testletStatusTypes } from '../../../../helpers/testletTypes';
import { isIterableArray } from '../../../../helpers/utils';

const isUserPartOfQuestion = (collaboratorIds, createdBy, signedInUserId) => {
  if (signedInUserId === createdBy) {
    return true;
  }

  if (isIterableArray(collaboratorIds) && collaboratorIds.includes(signedInUserId)) {
    return true;
  }

  return false;
};

export const validateAccess = (questionData, user) => {
  if (user === null || user === undefined) {
    throw new Error('No signed in user found');
  }

  if (isObjectNullOrEmpty(questionData)) {
    throw new Error('No data found against provided quesiton');
  }

  const { access_type, uid } = user;
  if (isUserAccessOfTypeAdmin(access_type)) {
    return;
  }

  const { collaboratorIds, testlet_type: content_status, created_by, is_deleted, version } = questionData;

  if (is_deleted) {
    throw new Error('This question has been deleted');
  }

  if (isUserPartOfQuestion(collaboratorIds, created_by, uid)) {
    return;
  }

  if (testletStatusTypes.APPROVED.includes(content_status) || version > 0) {
    return;
  }

  throw new Error('You are not allowed to view this question');
};
