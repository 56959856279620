import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import difficultyTooltipText from '../../../data/testlet/difficultyLevelTooltipText';

const DifficultyLevelToolTip = ({ id, difficultyLevel }) => {
  return (
    <>
      {difficultyLevel && (
        <UncontrolledTooltip placement="left" target={id}>
          {difficultyTooltipText[difficultyLevel]}
        </UncontrolledTooltip>
      )}
    </>
  );
};

DifficultyLevelToolTip.propTypes = {
  id: PropTypes.string,
  difficultyLevel: PropTypes.string
};

export default DifficultyLevelToolTip;
