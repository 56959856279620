import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { BaseContentActionButtons } from './BaseContentActionButtons';
import { BaseContentActions } from './BaseContentActions';
import { contentTypes } from '../../../data/content/contentTypes';
import { ContentStatusBadge } from '../../../widgets/content-badges/ContentStatusBadge';
import { isIterableArray } from '../../../helpers/utils';
import { testletStatusTypes } from '../../../helpers/testletTypes';

export const BaseContentActionsWrapper = ({
  contentData,
  contentId,
  deleteContent,
  handleCancel,
  isFormView,
  isLoading,
  isReview,
  isUserAdmin,
  isUserContentCreator,
  isUserPartialCollaborator,
  persistContent,
  saveContent,
  updateContentByAdmin,
  updateContentByFaculty,
  userId
}) => {
  const {
    collaboratorIds,
    content_type: contentType,
    testlet_type: contentStatus,
    published_inbdeCourse
  } = contentData;
  const contentName = contentTypes[contentType].name || 'Testlet';
  const [actionState, setActionState] = useState('');
  const [isConfirmationModelOpen, setIsConfirmationModelOpen] = useState(false);
  const [loadingState, setLoadingState] = useState(isLoading);
  const isQuestion = contentType === contentTypes.question.type;
  const isUserContentCollaborator = isIterableArray(collaboratorIds) && collaboratorIds.includes(userId);
  const mountedRef = useRef(null);

  let contentTitle;
  if (isQuestion) {
    const { question } = contentData;
    const { question_stem } = question;
    contentTitle = (question_stem && question_stem.text) || 'Stand-Alone Question';
  } else {
    const { testlet_information } = contentData;
    contentTitle = (testlet_information && testlet_information.testlet_title) || '<No-Title>';
  }

  const processContentAction = action => {
    if (action) {
      setActionState(action);
      toggleConfirmationModal();
    } else {
      // if content is opened via form then redirect, else close modal
      handleCancel();
    }
  };

  const toggleConfirmationModal = () => {
    setIsConfirmationModelOpen(!isConfirmationModelOpen);
  };

  useEffect(() => {
    mountedRef.current = true;

    if (isLoading && mountedRef.current ) {
      setLoadingState(true);
    } else if (loadingState && !isLoading && mountedRef.current ) {
      setLoadingState(false);
      setIsConfirmationModelOpen(false);
    }
    return(() => {
      mountedRef.current = false;
    });
  }, [isLoading, loadingState]);

  const formActionButtons = (
    <BaseContentActionButtons
      contentAction={processContentAction}
      contentData={contentData}
      contentId={contentId}
      contentName={contentName}
      contentStatus={contentStatus}
      isFormView={isFormView}
      isLoading={isLoading}
      isReview={isReview}
      isUserAdmin={isUserAdmin}
      isUserContentCollaborator={isUserContentCollaborator}
      isUserContentCreator={isUserContentCreator}
      isUserPartialCollaborator={isUserPartialCollaborator}
      saveContent={saveContent}
    />
  );
  return (
    <div>
      <BaseContentActions
        contentAction={actionState}
        contentName={contentName}
        deleteContent={deleteContent}
        isConfirmationModelOpen={isConfirmationModelOpen}
        isLoading={isLoading}
        persistContent={persistContent}
        publishedInbdeCourse={published_inbdeCourse}
        toggleConfirmationModal={toggleConfirmationModal}
        updateContentByAdmin={updateContentByAdmin}
        updateContentByFaculty={updateContentByFaculty}
      />
      {/* For large and x-large screens */}
      <div className="d-md-none">
        <Card>
          <CardBody>{formActionButtons}</CardBody>
        </Card>
      </div>
      {/* For small and medium screens */}
      <div
        className="form-action-buttons-container d-none d-md-flex w-100 fixed-bottom"
        style={{
          backgroundColor: 'rgba(93, 110, 129, 0.6)'
        }}
      >
        {!isFormView && (
          <div className="content-action-button-container-info d-none d-lg-flex col-12 col-md-5 mb-1 mb-md-0">
            <div className="d-flex mt-auto mb-auto w-100">
              <h5 className="font-weight-bold text-white text-truncate max-w-75 mb-auto mt-auto">
                {contentTitle} &emsp;
              </h5>
              {isQuestion && testletStatusTypes.APPROVED.includes(contentStatus) ? (
                ''
              ) : (
                <h5 className="mb-0 ml-2">
                  <ContentStatusBadge contentId={contentId} contentStatus={contentStatus} />
                </h5>
              )}
            </div>
          </div>
        )}
        <div className="form-action-buttons-content pb-2 pt-2 pr-md-3 pl-md-3 pr-lg-5 pl-lg-5 ml-auto">
          {formActionButtons}
        </div>
      </div>
    </div>
  );
};

BaseContentActionsWrapper.propTypes = {
  contentData: PropTypes.object.isRequired,
  contentId: PropTypes.string.isRequired,
  deleteContent: PropTypes.func,
  handleCancel: PropTypes.func,
  isFormView: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isReview: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  isUserContentCreator: PropTypes.bool,
  isUserPartialCollaborator: PropTypes.bool,
  persistContent: PropTypes.func,
  saveContent: PropTypes.func,
  updateContentByAdmin: PropTypes.func,
  updateContentByFaculty: PropTypes.func,
  userId: PropTypes.string
};

BaseContentActionsWrapper.defaultProps = {
  deleteContent: () => {},
  handleCancel: () => {},
  isLoading: false,
  isUserAdmin: false,
  isUserContentCreator: false,
  isUserPartialCollaborator: false,
  persistContent: () => {},
  saveContent: () => {},
  updateContentByAdmin: () => {},
  updateContentByFaculty: () => {},
  userId: ''
};
