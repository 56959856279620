import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Error500 from '../components/errors/Error500';
import Logo from '../components/navbar/Logo';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log to firebase analytics or some js crashalytics
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <>
          <Logo />
          <Error500 />
        </>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node
};

export default ErrorBoundary;
