export const answer = {
  id: 0,
  answer_choice: {
    text: '',
    attachments: []
  },
  explanation: {
    text: '',
    attachments: []
  },
  isCorrect: false
};
