import { contentMetaData } from './contentMetaData';
import { question } from './question';

export const standAloneQuestion = {
  ...contentMetaData,
  // question specific
  content_type: 'question',
  inbde_course: {},
  question: { ...question }
};
