import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { contentTypes } from '../../../data/content/contentTypes';
import { QuestionDetailCardBody } from './stand-alone-question/stand-alone-question';
import { PreviewAttachmentModal } from './PreviewAttachmentModal';
import { Spinner } from 'reactstrap';

export const ContentDetailCardBody = ({ contentData, showError }) => {
  const [filePath, setFilePath] = useState('');
  const [fileType, setFileType] = useState('');
  const [previewAttachmentOpen, setPreviewAttachmentOpen] = useState(false);
  const { content_type } = contentData;

  const togglePreviewModal = file => {
    try {
      if (!previewAttachmentOpen) {
        const fileType = file['content_type'].split('/').pop();
        setPreviewAttachmentOpen(true);
        setFilePath(file.url);
        setFileType(fileType);
      } else {
        setPreviewAttachmentOpen(false);
      }
    } catch (_error) {}
  };

  let content;
  if (content_type === contentTypes.question.type) {
    content = (
      <QuestionDetailCardBody
        contentType={content_type}
        questionData={contentData}
        previewAttachment={togglePreviewModal}
      />
    );
  } else {
    content = <div />;
  }

  return (
    <Fragment>
      {contentData === null ? (
        <div className="d-flex min-vh-50 align-items-center justify-content-center">
          {showError ? <h5>Failed to load data</h5> : <Spinner color="primary" className="avatar-3xl" />}
        </div>
      ) : (
        content
      )}
      <PreviewAttachmentModal
        filePath={filePath}
        fileType={fileType}
        previewAttachment={togglePreviewModal}
        previewModalFlag={previewAttachmentOpen}
      />
    </Fragment>
  );
};

ContentDetailCardBody.propTypes = {
  contentData: PropTypes.object.isRequired,
  showError: PropTypes.bool
};

ContentDetailCardBody.defaultProps = { showError: false };
