import React from 'react';
import PropTypes from 'prop-types';
import TypesBadge from '../TypeBadge';

export const CollaborationBadge = ({ contentId }) => {
  const className = 'nyu';
  const text = 'In Collaboration';
  const tooltip = 'You are a collaborator for this question';

  const details = { className, text, tooltip };

  return <TypesBadge contentId={contentId + '-collaboration'} details={details} />;
};

CollaborationBadge.propTypes = {
  contentId: PropTypes.string
};
