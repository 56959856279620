import React from 'react';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

const SpinnerModal = ({ background }) => {
  return (
    <div tabIndex="-1" style={{ position: 'relative', zIndex: 1050 }} className="d-block">
      <div className={'modal fade show d-block ' + background} tabIndex="-1">
        <div className="modal-dialog content-container m-auto h-100">
          <div className="content d-flex h-100">
            <Spinner className="avatar-3xl m-auto" />
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" />
    </div>
  );
};

SpinnerModal.propTypes = {
  background: PropTypes.string
};

SpinnerModal.defaultProps = {
  background: 'bg-100'
};

export default SpinnerModal;
