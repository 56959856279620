const types = [
  {
    title: 1,
    color: 'grey',
    class: 'secondary',
    text: 'In progress',
    tooltip: 'Draft, in progress'
  },
  {
    title: '1A',
    color: 'grey',
    class: 'secondary',
    text: 'In progress',
    tooltip: 'Draft, in progress'
  },
  {
    title: '1B',
    color: 'grey',
    class: 'secondary',
    text: 'Under Collaboration',
    tooltip: 'A collaborator is working on this testlet, but hasn’t submitted their changes for review'
  },
  {
    title: '2',
    color: 'orange',
    class: 'warning',
    text: 'Submitted for Review',
    tooltip: 'Under Review'
  },
  {
    title: '2A',
    color: 'orange',
    class: 'warning',
    text: 'Submitted for Review',
    tooltip: 'Under Review'
  },
  {
    title: '2B',
    color: 'orange',
    class: 'warning',
    text: 'Under Revision',
    tooltip: 'Changes Requested'
  },
  {
    title: '3',
    color: 'green',
    class: 'success',
    text: 'Open for collaboration',
    tooltip: 'Add new questions and suggest changes to the patient box in this existing testlet'
  },
  {
    title: '4',
    color: 'blue',
    class: 'primary',
    text: 'Make a copy',
    tooltip: 'Use this testlet as a base for a new testlet. Your changes will NOT affect the original testlet'
  },
  {
    title: '5',
    color: 'blue',
    class: 'primary',
    text: 'Make a copy',
    tooltip: 'Use this testlet as a base for a new testlet. Your changes will NOT affect the original testlet'
  }
];

export default types;
