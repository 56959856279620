import * as firebase from 'firebase/app';
import 'firebase/analytics';
import '../Firebase/FirebaseService';
import { getCurrentTimeStamp } from '../../helpers/inbdeUtils';

// Get a reference to the firebase analytics service
const analytics = firebase.analytics();

class FirebaseLoggingService {
  constructor(user) {
    if (user) {
      analytics.setUserProperties({ user });
    }
  }

  emailSentToUser(userId, purpose) {
    analytics.logEvent('email_sent_to_user', {
      userId,
      purpose,
      timestamp: getCurrentTimeStamp()
    });
  }

  errorParsingFile(exception) {
    const exceptionType = 'error_parsing_file';
    this.logInbdeException(exception, exceptionType);
  }

  logInbdeException(exception, exceptionType) {
    try {
      analytics.logEvent('inbde_exception', {
        rest: exception,
        type: exceptionType,
        timestamp: getCurrentTimeStamp()
      });
    } catch (error) {
      console.error(error);
    }
  }

  testletApprovedByAdmin(adminId, testletId) {
    analytics.logEvent('testlet_approved_by_admin', {
      userId: adminId,
      testletId,
      timestamp: getCurrentTimeStamp()
    });
  }

  testletPublishedByAdmin(adminId, testletId, inbdeCourse) {
    analytics.logEvent('testlet_published_by_admin', {
      userId: adminId,
      testletId,
      inbdeCourse,
      timestamp: getCurrentTimeStamp()
    });
  }

  testletRevisedByAdmin(adminId, testletId, comment) {
    analytics.logEvent('testlet_revised_by_admin', {
      userId: adminId,
      testletId,
      rest: comment,
      timestamp: getCurrentTimeStamp()
    });
  }

  testletSubmittedForReview(userId, testletId) {
    analytics.logEvent('testlet_submitted_for_review', {
      userId,
      testletId,
      timestamp: getCurrentTimeStamp()
    });
  }

  userHasLoggedIn(userId) {
    analytics.logEvent('user_logged_in', {
      userId,
      timestamp: getCurrentTimeStamp()
    });
  }

  userHasLoggedOut(userId) {
    analytics.logEvent('user_logged_out', {
      userId,
      timestamp: getCurrentTimeStamp()
    });
  }

  userHasSignedUp(userId) {
    analytics.logEvent('user_has_signed_up', {
      userId,
      timestamp: getCurrentTimeStamp()
    });
  }
}

export default FirebaseLoggingService;
