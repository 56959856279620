import { differenceWith, isEqual } from 'lodash';
import { getCurrentTimeStamp } from '../../helpers/inbdeUtils';

export function getCollaboratorModel(oldCollabs, newCollabs, toAdd) {
  if (toAdd) {
    const model = newCollabs[newCollabs.length - 1];
    model.added_at = getCurrentTimeStamp();
    model.removed_at = null;

    return model;
  } else {
    const model = differenceWith(oldCollabs, newCollabs, isEqual);
    const collabModel = !!model.length ? model[0] : oldCollabs[oldCollabs.length - 1];
    collabModel.added_at = null;
    collabModel.removed_at = getCurrentTimeStamp();

    return collabModel;
  }
}
