export const testletTypes = {
  DRAFT: '1A',
  COLLABORATION_DRAFT: '1B',
  SUBMITTED_FOR_REVIEW_TO_ADMIN: '2A',
  UNDER_REVISION: '2B',
  COLLABORATION_REVIEW_BY_TESTLET_CREATOR: '2C',
  OPEN_FOR_COLLABORATION: '3',
  UNPUBLISHED: '4',
  PUBLISHED: '5'
};

export const testletStatusTypes = {
  IN_PROGRESS: [testletTypes.DRAFT, testletTypes.COLLABORATION_DRAFT, testletTypes.UNDER_REVISION],
  UNDER_REVIEW: [testletTypes.SUBMITTED_FOR_REVIEW_TO_ADMIN, testletTypes.COLLABORATION_REVIEW_BY_TESTLET_CREATOR],
  APPROVED: [testletTypes.OPEN_FOR_COLLABORATION, testletTypes.UNPUBLISHED, testletTypes.PUBLISHED]
};
