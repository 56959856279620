import React from 'react';
import PropTypes from 'prop-types';
import { testletTypes, testletStatusTypes } from '../../../helpers/testletTypes';
import { Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { customContentRoutes } from '../../../routes';
import { contentTypes } from '../../../data/content/content';

export const BaseContentActionButtons = ({
  contentAction,
  contentData,
  contentId,
  contentName,
  contentStatus,
  isFormView,
  isLoading,
  isReview,
  isUserAdmin,
  isUserContentCreator,
  isUserContentCollaborator,
  isUserPartialCollaborator,
  saveContent
}) => {
  const { content_type, is_deleted, is_flagged, is_published, is_latest_version, version } = contentData;

  const isContentInProgress = testletStatusTypes.IN_PROGRESS.includes(contentStatus);
  const isContentPublished = Boolean(contentStatus === testletTypes.PUBLISHED && is_published);
  const isContentApproved = testletStatusTypes.APPROVED.includes(contentStatus);
  const isContentUnderReview = testletStatusTypes.UNDER_REVIEW.includes(contentStatus);
  const isCollaboration = Boolean(isUserContentCollaborator || isUserPartialCollaborator);
  const isStandAloneQuestion = content_type === contentTypes.question.type;

  let showEditContentButton;
  if (is_deleted || is_flagged || isFormView || (!is_latest_version && version !== 0)) {
    showEditContentButton = false;
  } else if (isReview) {
    showEditContentButton = true;
  } else if (isContentInProgress && (isUserContentCreator || isCollaboration)) {
    showEditContentButton = true;
  } else {
    showEditContentButton = false;
  }

  const showApproveContentButton = !is_deleted && isContentUnderReview && Boolean(isReview);
  const showDeleteContentButton = !is_deleted && Boolean(isContentInProgress && (isUserAdmin || isUserContentCreator));
  const showExportButton = !is_deleted && !is_flagged && Boolean(isUserAdmin) && isContentApproved;
  const showFlagContentButton =
    !is_flagged && !isUserAdmin && isContentApproved && !(isUserContentCreator || isCollaboration);
  const showMakeCopyButton = Boolean(
    !isFormView &&
      !isStandAloneQuestion &&
      (isUserAdmin || isContentPublished || contentStatus === testletTypes.UNPUBLISHED)
  );
  const showPublishContentButton = Boolean(
    !is_deleted && !is_flagged && !isContentPublished && isContentApproved && isReview
  );
  const showRepublishContentButton = Boolean(!is_deleted && !is_flagged && isContentPublished && isReview);
  const showRestoreContentButton = Boolean(is_deleted && !is_flagged && isReview);
  const showReviseContentButton = !is_deleted && !is_flagged && isContentUnderReview && Boolean(isReview);
  const showSaveButton = isFormView && !is_deleted && Boolean(isContentInProgress || isUserAdmin);
  const showSubmitContentButton = !is_deleted && isContentInProgress;
  const showUnflagContentButton = Boolean(isUserAdmin && is_flagged && isReview);
  const showUnpublishContentButton = Boolean(!is_deleted && !is_flagged && isContentPublished && isReview);

  const contentEditUrl = customContentRoutes[content_type].editPath;

  return (
    <div>
      {/* Form buttons and actions */}
      <div>
        {showEditContentButton && (
          <Link to={contentEditUrl.replace('{contentId}', contentId)} className="text-white">
            <Button color="success" className="mr-2">
              <FontAwesomeIcon icon="pencil-alt" className="d-inline mr-2 text-white" />
              Edit
            </Button>
          </Link>
        )}
        {showSaveButton && (
          <Button className="mr-2" color="success" onClick={() => saveContent()} disabled={isLoading}>
            Save
            {isLoading && <Spinner size="sm" color="light" className="ml-2" />}
          </Button>
        )}
        {showSubmitContentButton && (
          <Button color="primary" className="mr-2" onClick={() => contentAction('submit')} disabled={isLoading}>
            Submit for Review
          </Button>
        )}
        {showDeleteContentButton && (
          <Button color="danger" className="mr-2" onClick={() => contentAction('delete')} disabled={isLoading}>
            Delete
          </Button>
        )}
        {showApproveContentButton && (
          <Button color="primary" className="mr-2" onClick={() => contentAction('approve')} disabled={isLoading}>
            Approve
          </Button>
        )}
        {showReviseContentButton && (
          <Button color="danger" className="mr-2" onClick={() => contentAction('revise')} disabled={isLoading}>
            Revise
          </Button>
        )}
        {showRestoreContentButton && (
          <Button className="mr-2" color="success" onClick={() => contentAction('restore')} disabled={isLoading}>
            Restore
          </Button>
        )}
        {showPublishContentButton && (
          <Button className="mr-2" color="primary" onClick={() => contentAction('publish')} disabled={isLoading}>
            Publish
          </Button>
        )}
        {showUnpublishContentButton && (
          <Button className="mr-2" color="warning" onClick={() => contentAction('unpublish')} disabled={isLoading}>
            Unpublish
          </Button>
        )}
        {showRepublishContentButton && (
          <Button className="mr-2" color="primary" onClick={() => contentAction('republish')} disabled={isLoading}>
            Republish
          </Button>
        )}
        {showMakeCopyButton && (
          <Button className="mr-2" color="info" onClick={() => contentAction('copy')} disabled={isLoading}>
            Make a Copy
          </Button>
        )}
        {showFlagContentButton && (
          <Button color="danger" className="mr-2" onClick={() => contentAction('flag')} disabled={isLoading}>
            Flag
          </Button>
        )}
        {showUnflagContentButton && (
          <Button color="success" className="mr-2" onClick={() => contentAction('unflag')} disabled={isLoading}>
            Unflag
          </Button>
        )}
        {showExportButton && (
          <Button color="success" className="mr-2" onClick={() => contentAction('export')} disabled={isLoading}>
            <FontAwesomeIcon icon="file-pdf" className="d-inline mr-2 text-white" />
            Export
          </Button>
        )}
        <Button color="secondary" onClick={() => contentAction(null)} disabled={isLoading}>
          Close
        </Button>
      </div>
    </div>
  );
};

BaseContentActionButtons.propTypes = {
  contentAction: PropTypes.func,
  contentData: PropTypes.object.isRequired,
  contentId: PropTypes.string.isRequired,
  contentStatus: PropTypes.string.isRequired,
  contentName: PropTypes.string.isRequired,
  isFormView: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  isReview: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  isUserContentCreator: PropTypes.bool,
  isUserContentCollaborator: PropTypes.bool,
  isUserPartialCollaborator: PropTypes.bool,
  saveContent: PropTypes.func
};

BaseContentActionButtons.defaultProps = {
  contentAction: () => {},
  isLoading: false,
  isReview: false,
  isUserAdmin: false,
  isUserContentCreator: false,
  isUserContentCollaborator: false,
  isUserPartialCollaborator: false,
  saveContent: () => {}
};
