import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

class TypesBadge extends Component {
  render() {
    const { contentId, details, testletId } = this.props;
    const { className, text } = details;
    const id = 'inbde-' + (testletId || contentId);

    return (
      <>
        <span id={id} className={'badge fs-0 badge-soft-' + className}>
          {text}
        </span>
        <UncontrolledTooltip placement="top" target={id}>
          {details.tooltip}
        </UncontrolledTooltip>
      </>
    );
  }
}

TypesBadge.propTypes = {
  contentId: PropTypes.string,
  details: PropTypes.object.isRequired,
  testletId: PropTypes.string
};

export default TypesBadge;
