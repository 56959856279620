import React from 'react';
import PropTypes from 'prop-types';
import TypesBadge from '../TypeBadge';
import { contentTypes } from '../../data/content/contentTypes';

export const ContentTypeBadge = ({ contentId, contentType }) => {
  const selectedContentType = contentTypes[contentType] || contentTypes['testlet'];
  const contentName = selectedContentType.name;
  const contentColorTheme = selectedContentType.theme;

  const details = {
    className: contentColorTheme,
    text: contentName,
    tooltip: 'This is a ' + contentName
  };
  return <TypesBadge contentId={contentId + '-content_type'} details={details} />;
};

ContentTypeBadge.propTypes = {
  contentId: PropTypes.string.isRequired,
  contentType: PropTypes.string
};
