import { isProd } from './helpers/inbdeUtils';

const isProdEnv = isProd();
const environmentSuffix = isProdEnv ? 'PROD' : 'QA';

const application_properties = {
  client_id: process.env['REACT_APP_FIREBASE_PROJECT_CLIENT_ID_' + environmentSuffix],
  firebaseConfig: {
    apiKey: process.env['REACT_APP_FIREBASE_PROJECT_API_KEY_' + environmentSuffix],
    authDomain: process.env['REACT_APP_FIREBASE_PROJECT_AUTH_DOMAIN_' + environmentSuffix],
    databaseURL: process.env['REACT_APP_FIREBASE_DATABASE_URL_' + environmentSuffix],
    projectId: process.env['REACT_APP_FIREBASE_PROJECT_ID_' + environmentSuffix],
    storageBucket: process.env['REACT_APP_FIREBASE_PROJECT_STORAGE_BUCKET_URL_' + environmentSuffix],
    messagingSenderId: process.env['REACT_APP_FIREBASE_PROJECT_MESSAGE_SENDER_ID_' + environmentSuffix],
    appId: process.env['REACT_APP_FIREBASE_PROJECT_APP_ID_' + environmentSuffix],
    measurementId: process.env['REACT_APP_FIREBASE_PROJECT_MEASUREMENT_ID_' + environmentSuffix]
  }
};

export default application_properties;
