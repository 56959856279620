export const facultyContentStatus = {
  DRAFTS: {
    key: 'DRAFTS',
    types: [1, '1', '1A', '1B', '2B']
  },
  PENDING: {
    key: 'PENDING',
    types: ['2', '2A']
  },
  COLLAB_REVIEW: {
    key: 'COLLAB_REVIEW',
    types: ['2C']
  },
  UNPUBLISHED: {
    key: 'UNPUBLISHED',
    types: ['3', '4']
  },
  PUBLISHED: {
    key: 'PUBLISHED',
    types: ['5']
  }
};
